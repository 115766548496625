@import 'normalize.css';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Plus Jakarta Sans',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.price-card {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Adjust the width of the card as needed */
.price-card .ant-card {
	width: 240px;
	/* Set the desired width */
}

.ant-segmented-item {
	transition: red 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-segmented-item-selected {
	background-color: red;
}

.ant-segmented-thumb {
	background-color: red;
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #7c7878;

	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.autoplay-progress {
	position: absolute;
	right: 16px;
	bottom: 16px;
	z-index: 10;
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	color: var(--swiper-theme-color);
}

.autoplay-progress svg {
	--progress: 0;
	position: absolute;
	left: 0;
	top: 0px;
	z-index: 10;
	width: 100%;
	height: 100%;
	stroke-width: 4px;
	stroke: var(--swiper-theme-color);
	fill: none;
	stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
	stroke-dasharray: 125.6;
	transform: rotate(-90deg);
}


.checkbox-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	max-width: 600px;
	user-select: none;

	&>* {
		margin: .5rem 0.5rem;
	}
}



.checkbox-group-legend {
	font-size: 1.5rem;
	font-weight: 700;
	color: #9c9c9c;
	text-align: center;
	line-height: 1.125;
	margin-bottom: 1.25rem;
}

.checkbox-input {
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;

	&:checked+.checkbox-tile {
		border-color: #ecedf0;
		box-shadow: 0 5px 10px rgba(#000, 0.1);
		color: #dfe1e7;

		&:before {
			transform: scale(1);
			opacity: 1;
			background-color: #3983e0;
			border-color: #3983e0;
		}

		.checkbox-icon,
		.checkbox-label {
			color: #3983e0;
		}
	}

	&:focus+.checkbox-tile {
		border-color: #d1d3d8;
		box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #b5c9fc;

		&:before {
			transform: scale(1);
			opacity: 1;
		}
	}
}

.checkbox-tile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 7rem;
	min-height: 7rem;
	border-radius: 0.5rem;
	border: 2px solid #b5bfd9;
	background-color: #fff;
	box-shadow: 0 5px 10px rgba(#000, 0.1);
	transition: 0.15s ease;
	cursor: pointer;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		display: block;
		width: 1.25rem;
		height: 1.25rem;
		border: 2px solid #b5bfd9;
		background-color: #fff;
		border-radius: 50%;
		top: 0.25rem;
		left: 0.25rem;
		opacity: 0;
		transform: scale(0);
		transition: 0.25s ease;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
		background-size: 12px;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}

	&:hover {
		border-color: #2260ff;

		&:before {
			transform: scale(1);
			opacity: 1;
		}
	}
}

.checkbox-icon {
	transition: .375s ease;
	color: #494949;

	svg {
		width: 3rem;
		height: 3rem;
	}
}

.checkbox-label {
	color: #707070;
	transition: .375s ease;
	text-align: center;
}

.card-icon {
	color: #3983e0;

	svg {
		width: 3rem;
		height: 3rem;
	}
}

.category-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 5px;
	padding-top: 2rem;
}

@media (max-width: 1200px) {
	.category-grid {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (max-width: 768px) {
	.category-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 480px) {
	.category-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 768px) {
	.container-footer {
		grid-template-columns: 1fr;
		text-align: center;
	}

	.flex-footer {
		flex-direction: column;
		align-items: left;
		text-align: left;
		padding-top: 1.5rem;
	}

	img {
		margin: 0 auto;
	}
}

.responsive-image {
	width: 100%;
	height: 400px;
	/* border: 1px solid gray; */
	/* border-radius: 8px; */
	/* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}

@media (max-width: 768px) {
	.responsive-image {
		height: 250px;
	}
}

/* Loader.css */
.loader-container {
	display: flex;
	justify-content: center;
	gap: 1rem;
	align-items: center;
	height: 50vh;
	/* Full viewport height */
	background-color: white;
	/* Optional: set a background color */
}

.globe-icon {
	font-size: 30px;
	animation: rotate 2s linear infinite;
	color: #3983e0;
	/* Optional: set the color of the globe */
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.section-container {
	padding-top: 3rem;
	display: grid;
	gap: 1rem;
	justify-content: space-between;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	cursor: pointer;
	grid-template-columns: 1.2fr 1.8fr;
	/* flex: 1; */
	flex-wrap: wrap;
}

@supports not (display: grid) {
	.section-container {
		display: flex;
		flex-direction: column;
	}
}

@media (max-width: 768px) {
	.section-container {
		grid-template-columns: 1fr;
		/* Single column layout in mobile mode */
	}
}


/* Styles for the animated GIF */
.animated-gif {
	max-width: 100%;
	max-height: 100%;
	width: 100%;
	/* Full width */
	height: auto;
	border: 1px solid rgb(244, 236, 236);
	/* Adding border */
	border-radius: 8px;
	/* Adding border radius */
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	/* Adding shadow */
}

.inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}


@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.spin-icon {
	animation: spin 2s linear infinite;
}

/* place holder */

/* Change the placeholder color */


.custom-card .ant-card-body {
	padding: 10px;
	border-radius: 0 0 8px 8px;
}

.svelte-1r7dvt7 input {
	width: 100% !important;
}

.custom-menu-item {
	background-color: transparent !important;
	color: white !important;
}

.custom-menu-item:hover {
	background-color: #272B34 !important;
	color: white !important;
}

.selected-menu-item {
	background-color: #272B34 !important;
	color: white !important;
}

/* Custom untuk header tabel */
.ant-table-thead>tr>th {
	background-color: #363A45 !important;
	/* Warna background header */
	color: #fff !important;
	/* Warna teks header */
	text-align: center;
	/* Centang teks di tengah, jika diinginkan */
}

/* Custom untuk baris tabel */
.custom-row>td {
	background-color: #363A45 !important;
	/* Warna background baris */
	color: white !important;
	/* Warna teks */
}

/* Hover effect untuk baris tabel */
.custom-row:hover>td {
	background-color: #7d7d7d !important;
	/* Warna saat di-hover */
}

.ant-pagination-item a {
	color: #dfd6d6 !important;
}

.ant-pagination-item-active {
	background-color: #044637 !important;
	border-color: #08380e !important;
	border-radius: 50% !important;
}

.ant-pagination-item-active a {
	color: #fff !important;
}

.ant-pagination-prev,
.ant-pagination-next {
	color: #000 !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	background-color: #f0f0f0 !important;
	border-color: #d9d9d9 !important;
}


.custom-card .ant-card-body {
	padding: 10px;
	border-radius: 0 0 8px 8px;
}

.svelte-1r7dvt7 input {
	width: 100% !important;
}

.custom-menu-item {
	background-color: transparent !important;
	color: white !important;
}

.custom-menu-item:hover {
	background-color: #272B34 !important;
	color: white !important;
}

.selected-menu-item {
	background-color: #272B34 !important;
	color: white !important;
}

/* Custom untuk header tabel */
.ant-table-thead>tr>th {
	background-color: #363A45 !important;
	/* Warna background header */
	color: #fff !important;
	/* Warna teks header */
	text-align: center;
	/* Centang teks di tengah, jika diinginkan */
}

/* Custom untuk baris tabel */
.custom-row>td {
	background-color: #363A45 !important;
	/* Warna background baris */
	color: white !important;
	/* Warna teks */
}

/* Hover effect untuk baris tabel */
.custom-row:hover>td {
	background-color: #7d7d7d !important;
	/* Warna saat di-hover */
}

.ant-pagination-item a {
	color: #dfd6d6 !important;
}

.ant-pagination-item-active {
	background-color: #044637 !important;
	border-color: #08380e !important;
	border-radius: 50% !important;
}

.ant-pagination-item-active a {
	color: #fff !important;
}

.ant-pagination-prev,
.ant-pagination-next {
	color: #000 !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	background-color: #f0f0f0 !important;
	border-color: #d9d9d9 !important;
}


/* radius slider */
.radius-slider-container {
	padding: 20px;
	border: 1px, solid;
	background: 'transparent';
	border-radius: 8px;
	color: white;
}

.radius-slider-wrapper {
	/* display: flex; */
	flex-direction: column;
	justify-content: center;
}

.radius-slider-labels {
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	color: white;
	font-weight: bolder;
	/* margin-bottom: 10px; */
}

.radius-slider-range {
	display: flex;
	justify-content: space-between;
	font-size: 10px;
	/* margin-top: 10px; */
}

.custom-slider .ant-slider-track {
	background: linear-gradient(90deg, white 0%, #eceef1 100%);
	height: 5px;
	/* Adjust the height */
}

.custom-slider .ant-slider-handle {
	width: 18px;
	height: 10px;
	border: none;
	/* background-color: white; */
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.custom-slider .ant-slider-mark-text {
	display: none;
}

.custom-slider .ant-slider-dot {
	display: none;
}

.custom-slider .ant-slider-dot:first-child,
.custom-slider .ant-slider-dot:last-child {
	display: block;

}

.custom-slider .ant-slider-rail {
	background-color: #39444e;
	/* Default rail color */
}

/* Prevent hover from changing rail color */
.custom-slider .ant-slider:hover .ant-slider-rail {
	background-color: #39444e !important;
	/* Keep the same color on hover */
}

/* Ensure the active state does not change rail color */
.custom-slider .ant-slider:active .ant-slider-rail {
	background-color: #39444e !important;
	/* Keep the same color when active */
}

:where(.css-dev-only-do-not-override-1f43apd).ant-slider-horizontal.ant-slider-with-marks {
	margin-bottom: 10px;
}

/* radius slider  end*/

.custom-textarea::placeholder {
	color: white;
	/* Warna placeholder putih */
	opacity: 1;
	/* Bisa diatur untuk tingkat transparansi */
}

.white-placeholder::placeholder {
	color: white;
	opacity: 0.5;
	/* Optional */
}


/* mobile */

.custom-card-mobile .ant-card-body {
	padding: 10px;
	border-radius: 8px 8px 8px 8px;
}

.custom-row .ant-empty {
	background-color: #272B34;
	/* Custom background color */
	padding: 20px;
	/* Add some padding to make it look nicer */
	border-radius: 8px;
	/* Optional: rounded corners */
}

.custom-row .ant-empty-description {
	color: black;
	/* Change the color of the empty state text */
	font-weight: bold;
	/* Make the text bold */
	font-size: 16px;
	/* Adjust font size */
}

.radius-popup .maplibregl-popup-content {
	width: 180px;
	background-color: #333939 !important;
	background: #333939 !important;
	border-radius: 20px !important;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	padding: 15px 10px;
	pointer-events: auto;
	position: relative;
	height: 70px;
}

.reachability-popup .maplibregl-popup-content {
	width: 180px;
	background-color: #333939 !important;
	background: #333939 !important;
	border-radius: 20px !important;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	padding: 15px 10px;
	pointer-events: auto;
	position: relative;
	height: 130px;
}

.content-popup .maplibregl-popup-content {
	width: 300px;
	background-color: #333939 !important;
	background: #333939 !important;
	border-radius: 20px !important;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	padding: 15px 10px;
	pointer-events: auto;
	position: relative;
}

/* Apply black background and white text to the select body */
.ant-select-selector {
	background-color: #141617 !important; /* Black background for select body */
	color: #fff !important;            /* White text for selected option */
	border: none;                      /* Remove border for cleaner look */
  }
  
  /* Ensure that text inside the select is also white */
  .ant-select-selection-item {
	color: #fff !important;
  }

  /* Change the dropdown arrow color to white */
.ant-select-arrow {
	color: #fff !important; /* White color for the arrow */
  }
  

  @keyframes fade-in {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.fade-in {
    animation: fade-in 0.6s ease-in-out;
}



.ant-select-dropdown {
	background-color: #333939 !important; /* Latar belakang dropdown hitam */
  }
  
  .ant-select-item {
	color: white !important; /* Warna teks dropdown putih */
  }
  
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	color: rgb(255, 255, 255, 0.9) !important; /* Warna teks saat dipilih */
	font-weight: bold !important;
	background-color: #121212 !important; /* Latar belakang item terpilih */

  }
  
  .ant-select-item-option-active {
	background-color: #898787 !important; /* Warna hover */
	color: white !important;
  }
  